<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
              銷售應用中心
            </b-breadcrumb-item>
            <b-breadcrumb-item active>載具登錄列表</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card>
      <b-overlay :show="showLoading" rounded="sm">
        <div class="row d-flex mb-4 mb-xl-4 justify-content-between">
          <h4 class="col-12 col-xl-3 mb-1 mb-xl-0 font-weight-bold">
            載具登錄列表
          </h4>
          <div
            class="
              col-12 col-xl-9
              d-flex
              flex-column flex-xl-row
              align-items-start align-items-xl-center
            "
          >
            <b-form-select
              v-model="query.merchant_id"
              :options="merchants"
              @change="handlePageReset"
              :disabled="merchants.length == 2"
            >
            </b-form-select>
            <b-form-select
              class="ml-0 ml-xl-4"
              v-model="query.valid_status"
              :options="valid_status_options"
              @change="handlePageReset"
            >
            </b-form-select>
            <b-input-group class="ml-0 ml-xl-4" style="min-width: 300px">
              <b-form-input
                placeholder="搜尋載具號碼、會員名稱、手機"
                v-model="query.keyword"
                v-on:keyup.enter="handlePageReset"
              ></b-form-input>
              <b-input-group-append>
                <b-button @click="handlePageReset"
                  ><i class="fa fa-search"></i
                ></b-button>
              </b-input-group-append>
            </b-input-group>
          </div>
        </div>
        <b-table
          responsive
          striped
          hover
          :items="invoiceCarriers"
          :fields="fields"
          @sort-changed="handleSort"
        >
          <template #cell(last_query_time)="data">
            <div class="mb-1">
              {{ formatDate(data.item.last_query_time) }}
            </div>
          </template>
          <template #cell(last_accepted_at)="data">
            <div class="mb-1">
              {{ formatDate(data.item.last_accepted_at) }}
            </div>
          </template>
          <template #cell(created_at)="data">
            <div class="mb-1">
              {{ formatDate(data.item.created_at) }}
            </div>
          </template>
          <template #cell(valid_status)="data">
            <div>
              {{ valid_status_mapping[data.item.valid_status] }}
            </div>
          </template>
        </b-table>
        <CustomPagination
          :currentPage="query.page"
          :totalRows="totalRows"
          :perPage="query.per_page"
          @page-change="handlePageChange"
          @per-page-change="handlePerPageChange"
        />
      </b-overlay>
    </b-card>
  </div>
</template>
<script>
import merchantApi from "../../../apis/merchant";
import invoiceCarrierApi from "../../../apis/invoice-carrier";
import moment from "moment";
import PermissionChecker from "@/utils/PermissionChecker";
import * as consts from "@/consts";
import { updateQueryFromRoute, updateUrl } from "@/utils/updateUrl";
import CustomPagination from "@/components/Page/Dashboard/CustomPagination.vue";

export default {
  components: { CustomPagination },
  data() {
    return {
      consts,
      showLoading: false,
      merchants: [{ value: null, text: "請選擇" }],
      valid_status_options: [
        { value: null, text: "全部狀態" },
      ],
      valid_status_mapping: [],
      totalRows: 0,
      fields: [
        {
          key: "customer_name",
          label: "會員名稱",
        },
        {
          key: "card_type",
          label: "載具類型",
        },
        {
          key: "card_no",
          label: "載具號碼",
        },
        {
          key: "valid_status",
          label: "狀態",
        },
        {
          key: "last_query_time",
          label: "最近查詢時間",
          sortable: true,
          sortKey: "last_query_time",
        },
        {
          key: "last_accepted_at",
          label: "最近授權時間",
          sortable: true,
          sortKey: "last_accepted_at",
        },
        {
          key: "created_at",
          label: "登錄時間",
          sortable: true,
          sortKey: "created_at",
          sortDirection: "desc",
        },
      ],
      invoiceCarriers: [],
      permissionChecker: new PermissionChecker(),
      initialized: false,
      query: {
        page: 1,
        per_page: 10,
        keyword: "",
        sort_by: "created_at",
        order_by: "desc",
        merchant_id: null,
        valid_status: null,
      },
    };
  },
  created() {
    this.query = updateQueryFromRoute(
      this.$route,
      this.$store,
      this.query
    );
  },
  async mounted() {
    this.showLoading = true;

    await Promise.all([
      this.fetchMerchants(),
      this.fetchValidStatusOptions(),
    ])

    this.initialized = true;
    this.showLoading = false;
  },
  computed: {
    queryWatcher() {
      return `${this.query.page}-${this.query.per_page}`;
    },
  },
  watch: {
    queryWatcher: {
      handler() {
        if (!this.initialized) return;
        updateUrl(this.query, this.$store, this.$router);
      },
    },
    async $route(to) {
      if (!this.initialized) return;
      this.query = { ...to.query, valid_status: to.query.valid_status || null };
      this.showLoading = true;
      await this.fetchInvoiceCarriers();
      this.showLoading = false;
    },
  },
  methods: {
    async fetchValidStatusOptions() {
      const { data } = await invoiceCarrierApi.getValidStatusOptions();

      this.valid_status_mapping = data

      Object.keys(data).map((status_value) => {
        this.valid_status_options.push({ value: status_value, text: data[status_value] })
      })
    },
    async fetchInvoiceCarriers() {
      if (!this.query.merchant_id) return;

      try {
        const params = {
          ...this.query,
        };

        const { data } = await invoiceCarrierApi.getInvoiceCarriers(params);

        this.invoiceCarriers = data.data.map((invoiceCarrier) => ({
          customer_name: invoiceCarrier?.customer?.name,
          ...invoiceCarrier,
        }));
        this.totalRows = data.meta.total;
      } catch (error) {
        console.error(error);
        alert("取得發票載具資料錯誤");
      }
    },
    async fetchMerchants() {
      const { data } = await merchantApi.list();

      this.merchants = [
        { value: null, text: "請選擇" },
        ...data.data.map((merchant) => {
          return {
            value: merchant.id,
            text: `${merchant.name} (${merchant.type_name})`,
          };
        }),
      ];
      if (!this.query.merchant_id) {
        const merchants = data.data;
        const defaultMerchant =
          merchants.length === 1
            ? merchants[0]
            : merchants.find((merchant) => merchant.type === "LINE");

        if (defaultMerchant) {
          this.query.merchant_id = defaultMerchant.id;
        }
      }

      if (this.query.merchant_id) {
        const hasMerchant = this.merchants.find(
          (item) => item.value === this.query.merchant_id
        );
        if (!hasMerchant) {
          this.$swal.fire({
            icon: "warning",
            title: "查無此通路",
            confirmButtonText: "確定",
            confirmButtonColor: "#d33",
            reverseButtons: true,
          });
          return;
        }
        await this.fetchInvoiceCarriers();
      }
    },
    formatDate(value) {
      if (value) {
        return moment(String(value)).format("YYYY-MM-DD HH:mm");
      }
    },
    checkPermission(permissions) {
      return this.permissionChecker.check(permissions);
    },
    handleSort(ctx) {
      this.query.sort_by = ctx.sortBy;
      this.query.order_by = ctx.sortDesc ? "desc" : "asc";
      this.handlePageReset();
    },
    handlePageChange(newPage) {
      this.query.page = newPage;
    },
    handlePerPageChange(newPerPage) {
      this.query.per_page = newPerPage;
      this.query.page = 1;
    },
    handlePageReset() {
      const currentPage = parseInt(this.query.page, 10);

      if (currentPage === 1) {
        updateUrl(this.query, this.$store, this.$router);
      } else {
        this.query.page = 1;
      }
    },
  },
};
</script>

<style>
.modal-backdrop {
  opacity: 0.5;
}
</style>
